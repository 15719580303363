<template>
    <div class="inner_pages">
        <!-- <inner-sidebar /> -->
        <div class="respective_content" id="file_index">
            <div class="acton_header">
                <div class="left_section">
                    <h2>Tags</h2>
                    <div class="form_grp filter_grp">
                        <multiselect
                            v-model="tag_id"
                            :groups="true"
                            :searchable="true"
                            :options="tagsGroup"
                            value-prop="id"
                            label="name"
                            placeholder="Select tag"
                            :group-select="false"
                            group-label="type"
                            group-options="tags"
                            :group-hide-empty="true"
                        >
                            <template v-slot:grouplabel="{ group }">
                                <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                            </template>
                        </multiselect>
                    </div>
                    <button type="button" class="edit" @click="handleEditTag()" v-if="tag_id"><i class="fas fa-pen"></i></button>
                    <!-- <button type="button" class="video_btn" @click="tutorial = true;"><i class="fas fa-play"></i></button>
                    <button type="button" class="add_btn pointer"  @click="editTag = true; isEdit = false"><i class="fas fa-plus"></i>Add Tag</button> -->
                </div>
                <div class="right_section">
                    <!-- <button type="button" @click="editTag = true; isEdit = false">New Tag</button> -->
                    <div class="tutorial_btn" @click="tutorial = true;"><label>Tutorials</label><img src="@/assets/images/play.svg"></div>
                    <ul>
                        <li class="add_btn pointer"  @click="editTag = true; isEdit = false"><i class="fas fa-plus-circle"></i>Add Tag</li>
                    </ul>
                </div>
            </div>
            <div v-show="tag_id">
                <contact-list :contacts="contacts" :loader="loader" :get-contacts="handleTagContacts" :contact-watcher="contactWatcher" :deleted-through="'Tags'"  module="tag" />
            </div>
            <div class="index_content" v-show="!tag_id">
                <div class="mt-5 w-100">
                    <div class="empty_section right">
                        <img src="@/assets/images/curve_arrow.svg" alt="" class="arrow">
                        <h1>Select a Tag from the dropdown</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <edit-tag v-model="editTag" :is-edit="isEdit" :tag="selectedTag" />
    <div class="modal secondary video_player" v-if="tutorial">
        <div class="modal_container">
            <div class="modal_header">
                <button class="close_btn" @click="tutorial = false"><i class="fas fa-times"></i></button>
            </div>
            <div class="video_wpr">
                <iframe src="https://www.loom.com/embed/6aa7cd58146b46959e813bc9d510bb86?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </div>
            <div class="modal_footer">
                <a href="https://help.thrivecoach.io/" target="_blank" class="more_tutorial">More Tutorials <i class="fas fa-long-arrow-alt-right"></i></a>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'
    
    const EditTag = defineAsyncComponent(() => import('@/pages/tag/components/EditTag'))
    const ContactList = defineAsyncComponent(() => import('@/components/ContactList'))

    import moment from 'moment'
    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Tag Dashboard',

        data () {
            return{
                tutorial: false,
                tag_id: '',
                bulkActionDropdown: false,
                moment,
                contactWatcher: 0,
                selectedTag: {},
                editTag: false,
                isEdit: false,
                deletedThrough: 'Tags',
            }
        },

        components: {
            ContactList,
            EditTag,
        },

        watch: {
            'tag_id' (val) {
                const vm = this;

                vm.contactWatcher += 1;
            },

            editTag (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            bulkActionWatcher (val) {
                const vm = this;

                vm.contactWatcher += 1;
            }
        },

        computed: {
            ...mapState({
                tagsGroup: state => state.tagModule.tagsGroup,
                tags: state => state.tagModule.tags,
                contacts: state => state.tagModule.contacts,
                loader: state => state.tagModule.tagLoader,
                bulkActionWatcher: state => state.contactModule.bulkActionWatcher,
            }),
        },

        mounted () {
            const vm = this;

            if (vm.tagsGroup.length == 0) {
                vm.getTags();
            }

            vm.contactWatcher += 1;

            if (vm.$route.query.tag_id) {
                setTimeout(() => {
                    vm.tag_id = vm.$route.query.tag_id;
                }, 1000);
            }
        },

        methods:{
            ...mapActions({
                getTags: 'tagModule/getTags',
                getTagContacts: 'tagModule/getTagContacts',
            }),

            handleTagContacts (params) {
                const vm = this;

                params.tag_id = vm.tag_id;

                vm.getTagContacts(params);
            },

            handleEditTag () {
                const vm     = this;
                const result = vm.tags.find( ({ id }) => id == vm.tag_id );

                if (result && result.type != 'System Tags') {
                    vm.selectedTag = result;
                    vm.isEdit      = true;
                    vm.editTag      = true;
                } else {
                    Toastr.error(`This is a system tags! This can't be edited!`)
                }
            }
        }
    }
</script>

<style scoped>
    .acton_header{
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
        flex-wrap: wrap;
    }
    .acton_header .left_section{
        flex: 1;
    }
    .acton_header .left_section button.edit{
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2f7eed;
        border-radius: 50%;
        font-size: 12px;
        color: #fff;
    }
    .filter_grp{
        width: 200px;
        padding:0;
        background: #fff;
        margin: 0 0 0 20px;
        border-radius: 17px;
    }
    .filter_grp .multiselect{
        width: 100%;
        min-height: 35px;
        font-size: 13px;
    }
    .filter_grp .multiselect :deep(.multiselect-search){
        background: transparent;
    }
    .filter_grp .multiselect :deep(.multiselect-dropdown > ul.multiselect-options > li){
        box-shadow: none;
        height: auto;
        justify-content: flex-start;
    }
    .filter_grp .multiselect :deep(.multiselect-dropdown){
        left: auto;
        right: 0;
    }
    .filter_grp :deep(.multiselect ul li){
        border: 0;
        border-radius: 0;
    }
    .acton_header .right_section{
        display:flex;
        gap: 15px;
    }
    .acton_header .right_section .tutorial_btn{
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .acton_header .right_section .tutorial_btn label {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #2f7eed;
        cursor: pointer;
        min-width: auto;
    }
    .acton_header .right_section .tutorial_btn img {
        max-height: 18px;
        width: auto;
        margin-left: 5px;
    }
    .acton_header .right_section .add_btn{
        flex-shrink: 0;
    }
    .acton_header .right_section button.edit{
        border: 1px solid #e9e9e9;
        width: 31px;
        height: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        color: #5a5a5a;
        border-radius: 17px;
        margin-left: 0;
    }

    .playbook_list li .tag {
        padding: 5px 10px;
        font-size: 12px;
        line-height: 15px;
        color: #fff;
        border-radius: 3px;
        background: #2f7eed;
        text-transform: uppercase;
    }

    .playbook_list {
        list-style-type: none;
        margin: 0 -20px;
        display: flex;
        flex-wrap: wrap;
    }

    .action_content .upload_image .dropzone,
    .action_content .upload_image label {
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .action_content .upload_image .dropzone .icon,
    .action_content .upload_image label .icon {
        height: 70px;
        width: 70px;
    }

    .upload_image .dropzone {
        border: 1.2px dashed #95BFFF;
        background: #FAFCFD;
        display: block;
        padding: 25px 25px 15px 25px;
    }

    .playbook_list>li {
        padding: 20px;
        flex: 0 1 33.333%;
        min-width: 255px;
        /* width: 33.333%; */
    }

    .plybook_item {
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        line-height: 0;
        position: relative;
        height: 100%;
    }

    .replace_img {
        position: absolute;
        right: 15px;
        top: 15px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 2;
    }

    .replace_img img {
        max-width: 16px;
        height: auto;
    }

    .plybook_item .item_img .checkbox {
        position: absolute;
        left: 15px;
        top: 15px;
    }
    .plybook_item .item_img .checkbox span{
        border-color: #8a8a8a;
    }
    .plybook_item .item_img .checkbox span i {
        color: #2c3e50;
    }

    .plybook_item .item_img {
        height: auto;
        position: relative;
        overflow: hidden;
        border-radius: 10px 10px 0 0;
    }

    .plybook_item .item_img .img {
        height: auto;
        width: 100%;
        object-fit: cover;
    }

    .plybook_item .item_img .statistics {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        /* background: linear-gradient(to right, rgba(243, 243, 243, 1), rgba(255,255,255,0.8) 70%, rgba(255,255,255,0.5)); */
        background: #f5f5f5;
        padding: 30px 15px 15px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .plybook_item .item_img .statistics li {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #2C3E50;
        padding: 3px 0;
        text-align: left;
    }

    .plybook_item .item_img .statistics li:first-child {
        margin-bottom: auto;
    }

    .plybook_item .img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        border-radius: 10px 10px 0 0;
    }

    .plybook_item .item_ttl {
        font-size: 16px;
        line-height: 22px;
        color: #121525;
        font-weight: 400;
        padding: 15px 20px 0 20px;
        margin-bottom: 15px;
        text-align: left;
        display: block;
        text-decoration: none;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .plybook_item .item_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 20px 20px;
    }

    .plybook_item .item_info .member {
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info img {
        margin-right: 5px;
        max-height: 16px;
        width: auto;
    }

    .plybook_item .item_info>ul {
        list-style-type: none;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info>ul>li {
        width: 30px;
        height: 30px;
        font-size: 15px;
        color: #2f7eed;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #f3f3f3;
        margin-left: 8px;
        cursor: pointer;
        position: relative;
    }

    .plybook_item .item_info .row_option {
        bottom: -10px;
        top: auto;
        animation: dropMove2 .5s ease-in-out;
    }

    .plybook_item .item_info .row_option:before {
        bottom: 18px;
        top: auto;
        left: auto;
        transform: rotate(45deg) scale(1);
    }

    .plybook_item .item_img:hover .statistics,
    .plybook_item .item_img.active .statistics {
        opacity: 1;
    }

    @keyframes dropMove2 {
        from {
            margin-bottom: 10px;
            opacity: 0;
        }

        to {
            margin-bottom: 1px;
            opacity: 1;
        }
    }

    .share_playbook .modal_container {
        height: 640px;
    }

    .modal.share_playbook .modal_header {
        padding: 20px 30px 0;
    }

    .modal.share_playbook .modal_body {
        padding: 0 30px;
        flex-direction: column;
    }

    .share_playbook .tab_row {
        padding: 20px 0 0 0;
        display: flex;
    }

    .share_playbook .tab_row li {
        padding: 8px 20px;
        position: relative;
        cursor: pointer;
    }

    .share_playbook .tab_row li.active {
        background: #eaeaea;
        border-radius: 5px 5px 0 0;
    }

    .share_playbook .tab_wpr {
        width: 100%;
    }

    .share_playbook .result_wpr {
        width: 100%;
    }

    table button {
        padding: 3px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 7px;
    }

    table button.success {
        background: #00aa14;
    }

    table button.failed {
        background: #f04438;
    }

    .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .folder_list {
        display: flex;
        margin-top: 20px;
    }

    .folder_list li {
        width: 33.333%;
    }

    .sorting_card li {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
    }

    .sorting_card li img {
        width: 60px;
        height: 60px;
        object-fit: cover;
    }

    .sorting_card .card_details {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        padding: 10px 15px;
    }

    .sorting_card .card_details p {
        font-size: 10px;
        line-height: 13px;
        font-weight: 400;
        color: #5a5a5a;
        margin-top: 3px;
    }

    .sorting_card li:hover {
        background: #fff;
        box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.13);
    }

    .result_wpr.small .actions>ul {
        height: 35px;
    }

    .result_wpr.small .actions>ul li,
    .result_wpr.small .actions>ul li input {
        padding: 7px 10px;
        font-size: 14px !important;
    }

    .result_wpr.small .actions>ul li .dropdown_wpr {
        width: 100%;
    }

    .result_wpr.small table td {
        padding: 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table th {
        padding: 7px 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table td .user_img,
    .result_wpr.small table td .user_img img {
        width: 30px;
        height: 30px;
    }

    .result_wpr.small table td h4 {
        font-size: 13px;
        line-height: 16px;
        color: #2f7eed;
        font-weight: 500;
    }

    .result_wpr table.standard td:first-child,
    .result_wpr table.standard th:first-child {
        width: auto;
        text-align: left;
    }

    .row_option li a {
        color: inherit;
        text-decoration: none;
    }

    .action_list {
        display: flex;
        flex-wrap: wrap;
    }

    .action_list li {
        margin-right: 5px;
        cursor: pointer;
    }

    .action_list li i.fa-envelope {
        color: #f2a31d;
    }

    .action_list li i.fa-comment {
        color: #2f7eed;
    }

    .action_list li i.fa-trash-alt {
        color: #eb1414;
    }

    .action_list li i.fa-search-plus {
        color: #32373b;
    }

    .folders_list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 -5px;
    }

    .folders_list li {
        padding: 0 5px;
    }

    .folders_list .folder_item {
        border: 1px solid #E9E9E9;
        padding: 10px 15px;
        border-radius: 9px;
        background: #fff;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        font-size: 13px;
        line-height: 17px;
        color: #5A5A5A;
        font-weight: 400;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        height: 100%;
        cursor: pointer;
    }

    .folders_list .folder_item img {
        max-width: 30px;
        height: auto;
        margin-right: 10px;
    }

    .folders_list .folder_item:hover {
        box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.13)
    }

    .folders_list .folder_item .drp_wrapper {
        display: none;
    }

    .folders_list .folder_item .dropdown {
        margin-left: 20px;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper {
        display: block;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper ul {
        padding: 5px 0;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper ul li {
        font-size: 10px;
        line-height: 12px;
        padding: 4px 8px;
        text-align: left;
    }

    .modal.redirect_modal .modal_container {
        height: 420px;
    }

    .modal.redirect_modal .section_ttl {
        font-size: 16px;
        line-height: 20px;
        color: #5a5a5a;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        width: 100%;
        margin: 0 0 10px;
    }

    .modal.redirect_modal .section_ttl span {
        padding: 0 10px;
        background: #fff;
    }

    .modal.redirect_modal .section_ttl:after {
        content: '';
        position: absolute;
        left: 7px;
        right: 7px;
        top: 50%;
        border-bottom: 1px solid #e9e9e9;
        z-index: -1;
    }

    .redirect_list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .redirect_list li {
        width: 33.333%;
        padding: 5px 8px;
    }

    .redirect_list li label h5 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        margin: 0;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        padding: 10px;
        cursor: pointer;
        background: rgba(242, 163, 29, 0.05);
        border: 1px solid rgba(242, 163, 29, 0.3);
        color: #757575;
        filter: grayscale(1);
    }

    .redirect_list li label input[type=radio]:checked~h5 {
        filter: grayscale(0);
        color: #f2a31d;
    }

    .redirect_action {
        width: 100%;
        padding: 0 8px;
    }

    .modal.redirect_modal .action_wpr {
        margin-top: auto;
    }

    .ques_listing li {
        font-size: 14px;
        line-height: 19px;
        list-style-type: decimal;
        margin-left: 17px;
        padding: 10px 0 10px 5px;
        border-bottom: 1px solid #e9e9e9;
    }

    .preview_area .close_btn {
        right: 15px;
        top: 15px;
        left: auto;
        position: fixed;
        background: #eaeaea;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        cursor: pointer;
    }

    .modal .preview_area.show_sidebar {
        left: 0;
        width: 100%;
    }

    .storage {
        padding: 10px 15px;
        background: #fff;
        border: 1px solid #f5f5f5;
        border-radius: 5px;
        position: relative;
        width: 300px;
        overflow: hidden;
    }

    .storage h4 {
        font-size: 13px;
        line-height: 15px;
        color: #757575;
        font-weight: 500;
        position: relative;
        z-index: 1;
    }

    .storage span {
        background: rgba(47, 126, 237, 0.1);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
    }

    .plybook_item .item_ttl span.edit {
        cursor: auto;
    }

    .plybook_item .item_ttl span.save {
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }

    :deep(.multiselect-group-label) {
        padding: var(--ms-group-label-py,.3rem) var(--ms-group-label-px);
    }

    .index_content {
        max-width: 1300px;
        margin: 0 auto;
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
        position: relative;
    }

    .index_content h3 {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 10px;
        justify-content: center;
        max-width: none;
        font-weight: 400;
    }

    .empty_section{
        background: #fff;
        border-radius: 10px;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }
    .empty_section.right .arrow{
        transform: scaleX(1) translateY(30%);
        left: 8%;
    }

    @media(max-width: 767px){
        .filter_grp .multiselect{
            min-height: 30px;
        }
        .acton_header .left_section button.edit{
            width: 28px;
            height: 28px;
            margin-left: 10px;
        }
    }
    @media(max-width: 600px){
        .acton_header .left_section, .acton_header .right_section{
            width: 100%;
            flex: 0 0 100%;
        }
        .filter_grp, .acton_header .right_section ul{
            margin-left: auto;
        }
    }
</style>
